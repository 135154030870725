<template>
	<S2SForm title="Upload Statement">
		<div
			id="fileUploadABSA"
			:class="draggedOverABSA"
			v-cloak
			@drop.prevent="dropFile"
			@dragover.prevent
			@dragenter="changeClass('ABSA')"
			@dragleave="changeClass('ABSA')"
			@click="selectFile('fileInputABSA')"
		>
			<h3 class="text-center">ABSA</h3>
			<div class="text-center">Drop file here or click to upload.</div>
			<input id="fileInputABSA" style="visibility: hidden" type="file" @change="uploadFile" />
			<v-progress-linear v-if="uploadInProgressAbsa" indeterminate color="#d72730"></v-progress-linear>
		</div>
		<div v-if="fileABSA" class="text-center" align-baseline>
			<div class="subheading font-weight-medium">
				{{ fileABSA.name }}

				<span style="color: red" v-if="fileABSA.status === 'error'">{{ fileABSA.errorMessage }}</span>
				<span style="color: green" v-else>File Uploading</span>
			</div>
		</div>

		<v-divider class="py-1" style="border: none"></v-divider>

		<div
			id="fileUploadNedbank"
			:class="draggedOverNedbank"
			v-cloak
			@drop.prevent="dropFile"
			@dragover.prevent
			@dragenter="changeClass('Nedbank')"
			@dragleave="changeClass('Nedbank')"
			@click="selectFile('fileInputNedbank')"
		>
			<h3 class="text-center">Nedbank</h3>
			<div class="text-center">Drop file here or click to upload.</div>
			<input id="fileInputNedbank" style="visibility: hidden" type="file" @change="uploadFile" />
			<v-progress-linear v-if="uploadInProgressNedbank" indeterminate color="#266239"></v-progress-linear>
		</div>
		<div v-if="fileNedbank" class="text-center" align-baseline>
			<div class="subheading font-weight-medium">
				{{ fileNedbank.name }}

				<span style="color: red" v-if="fileNedbank.status === 'error'">{{ fileNedbank.errorMessage }}</span>
				<span style="color: green" v-else>File Uploading</span>
			</div>
		</div>
	</S2SForm>
</template>

<script>
import Vue from "vue";

import { statement } from "../../api/statement-api";

export default Vue.extend({
	name: "UploadStatement",

	data: function() {
		return {
			uploadInProgressAbsa: false,
			uploadInProgressNedbank: false,

			fileABSA: null,
			fileNedbank: null,
			draggedOverABSA: "",
			draggedOverNedbank: ""
		};
	},

	methods: {
		selectFile(inputId) {
			document.getElementById(inputId).click();
		},
		async uploadFile(e) {
			if (e.target.id === "fileInputABSA") {
				this.uploadInProgressAbsa = true;
				this.fileABSA = e.target.files[0];

				await statement
					.uploadAbsaStatement(this.fileABSA)
					.then(request => {
						this.$store.dispatch("notifications/success", `Absa File ${this.fileABSA.name} Upload Success`);
						this.uploadInProgressAbsa = FALSE;
						this.fileABSA = null;
					})
					.catch(error => {
						this.$store.dispatch("notifications/error", `Absa File ${this.fileABSA.name} Upload Failure`);
					});
			} else {
				this.uploadInProgressNedbank = true;
				this.fileNedbank = e.target.files[0];

				await statement
					.uploadNedbankStatement(this.fileNedbank)
					.then(request => {
						this.$store.dispatch("notifications/success", `Nedbank File ${this.fileNedbank.name} Upload Success`);
						this.uploadInProgressNedbank = false;
						this.fileNedbank = null;
					})
					.catch(error => {
						this.$store.dispatch("notifications/error", `Nedbank File ${this.fileNedbank.name} Upload Failure`);
					});
			}
		},
		async dropFile(e) {
			if (e.toElement.id === "fileUploadABSA") {
				this.uploadInProgressAbsa = true;
				this.fileABSA = e.dataTransfer.files[0];

				await statement
					.uploadAbsaStatement(this.fileABSA)
					.then(request => {
						this.$store.dispatch("notifications/success", `Absa File ${this.fileABSA.name} Upload Success`);
						this.uploadInProgressAbsa = false;
						this.fileABSA = null;
					})
					.catch(error => {
						this.$store.dispatch("notifications/error", `Absa File ${this.fileABSA.name} Upload Failure`);
					});
			} else {
				this.uploadInProgressNedbank = true;
				this.fileNedbank = e.dataTransfer.files[0];

				await statement
					.uploadNedbankStatement(this.fileNedbank)
					.then(request => {
						this.$store.dispatch("notifications/success", `Nedbank File ${this.fileNedbank.name} Upload Success`);
						this.uploadInProgressNedbank = false;
						this.fileNedbank = null;
					})
					.catch(error => {
						this.$store.dispatch("notifications/error", `Nedbank File ${this.fileNedbank.name} Upload Failure`);
					});
			}
			this.changeClass(e.toElement.id);
		},
		changeClass(bank) {
			if (bank === "ABSA" || bank === "fileUploadABSA") {
				if (this.draggedOverABSA === "") {
					this.draggedOverABSA = "dragging";
				} else {
					this.draggedOverABSA = "";
				}
			} else {
				if (this.draggedOverNedbank === "") {
					this.draggedOverNedbank = "dragging";
				} else {
					this.draggedOverNedbank = "";
				}
			}
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}

#fileUploadABSA,
#fileUploadNedbank {
	height: 20vh;
	border: 2px dashed #ccc;
	margin-top: 20px;
	padding: 10px;
	border-radius: 5px;
}

#fileUploadABSA.dragging,
#fileUploadNedbank.dragging {
	background: #e1f5fe repeating-linear-gradient(-45deg, #fafafa 25%, transparent 25%, transparent 50%, #fafafa 50%, #fafafa 75%);
	background-size: 40px 40px;
}
</style>
